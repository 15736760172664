import InstructorsService from '@/services/instructors.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const instructors = {
  namespaced: true,
  state: initialState,
  actions: {
    getInstructor ({ commit }: any, id: string) {
      return InstructorsService.getInstructor(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getInstructors ({ commit }: any, params: string) {
      return InstructorsService.getInstructors(params).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getInstructorStudents ({ commit }: any, params: any) {
      return InstructorsService.getInstructorStudents(params).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteInstructor ({ commit }: any, id: string) {
      return InstructorsService.deleteInstructor(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
