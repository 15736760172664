import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AuthService {
  login (user: any) {
    return axios
      .post(API_URL + 'admin/auth/login', {
        email: user.email,
        password: user.password
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('admin-sra', JSON.stringify(response.data.access_token))
          localStorage.setItem('admin-sra-name', JSON.stringify(response.data.data.email))
        }
        return response.data
      })
  }

  getSchools (id: string) {
    return axios
      .get(API_URL + 'admin/instructors/' + id + '/fields/schools', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  logout () {
    return axios
      .post(API_URL + 'admin/auth/logout', {
      }, { headers: authHeader() })
  }
}

export default new AuthService()
