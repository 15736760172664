
import { defineComponent } from 'vue'
import Logo from '@/assets/svg/logo.svg?inline'
import MobileAlert from '@/assets/svg/mobile-alert.svg?inline'

export default defineComponent({
  name: 'Header',
  data () {
    return {
      mobileMenu: false,
      errorLogout: null,
      email: ''
    }
  },
  components: {
    Logo,
    MobileAlert
  },
  mounted () {
    this.email = JSON.parse(localStorage.getItem('admin-sra-name'))
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(
        () => {
          this.$router.push('/')
        },
        (error) => {
          this.errorLogout = error.response.data.errors
        }
      )
    }
  }
})
